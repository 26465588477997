<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="6">
          <div class="clearfix">
            <h1 class="float-left display-3 mr-4">404</h1>
            <h4 class="pt-3">Ups!.</h4>
            <p class="text-muted">Halaman yang Anda cari tidak ditemukan.</p>
          </div>
          <b-input-group>
            <b-button variant="pgiBtn" @click="beforeRouteEnter"
              >Kembali ke halaman sebelumnya!</b-button
            >
          </b-input-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page404",
};
</script>
